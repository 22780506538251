@import '../../styles/_variables';
@import '../../styles/_mixins';

.table_orders {
  $self: &;

  thead {
    tr {
      #{$self}__th_checkbox {
        min-width: auto;
        width: rem(24px);
      }

      #{$self}__th_metro {
        width: rem(130px);
      }
    }
  }

  & &__loading-time-range {
    white-space: nowrap;
  }

  & &__driver-phone {
    white-space: nowrap;
  }
}
