.react-quill-container {
  height: 30vh;
}

.ql-toolbar.ql-snow {
  border-color: #dadada;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border: 2px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.ql-container.ql-snow {
  border: none;
}

.ql-editor {
  white-space: pre !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border: 2px solid #dadada;
  border-top: 1px solid #dadada;
}

.ql-tooltip {
  left: 1% !important;
}

.screen-links__grid_attachment {
  margin-top: 5%;
  border: 2px #dadada solid;
  border-radius: 0.625rem;
  padding: min(15px, 4%);
  transition: box-shadow 0.2s linear;
}

.screen-links__grid_attachment:hover {
  border-color: #a0a293;
}

.date__container {
  display: flex;
  justify-content: center;
}

.driver__badge__container {
  width: auto;
  height: 1.7rem;
  border-radius: 3px;
  padding: 0.2rem 0.3rem;
  background-color: #eeeeee;
  box-shadow: 1px 1px 2px #bbbbbb;
  margin: 0 !important;
}

.selected__drivers {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.metro__select__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.image-icon-div {
  padding: 10px 20px 10px 20px
}

.image-icon {
  width: 30px;
  height: 30px;
}

.md-textarea {
  resize: none;
  overflow: auto;
  border-radius: 10px;
  padding: 8px 15px;
  border: 2px solid #dadada;
  outline: none;
  height: 15rem;
  width: 28rem;
  flex-direction: column;
  display: flex
}

.md-textarea:hover {
  border-color: #a0a293;
}

.image_attachment {
  position: relative;
  border: 2px #dadada solid;
  border-radius: 0.625rem;
  padding: min(200px, 3%);
  transition: box-shadow 0.2s linear;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  object-fit: contain;
  background-color: #fff;
  height: 15rem;
  width: 28rem;
}

.image_attachment:hover {
  border-color: #a0a293;
}

.search {
  width: 16rem;
  height: 40px;
  padding: 0 30px;
  border-radius: 15px;

  &:focus {
    outline: none;
  }
}

.search_icon {
  position: absolute;
  top: 11px;
  left: 8px;
  font-size: 1.2rem;
}

.userSeens {
  margin-left: 20px;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 20px;
}

.detail_body {
  width: 100%;
  height: 10rem;
  overflow: auto;
}

.metro_data {
  height: 66vh;
  overflow: auto;
  border: 2px solid #dadada;
  border-radius: 10px;

  @media (max-width: 1200px) {
    height: 50vh;
  }
}

.seen_unseen {
  height: 45vh;
  overflow: auto;
  border: 2px solid #dadada;
  border-radius: 10px;

  @media (max-width: 1200px) {
    height: 40vh;
  }

  @media (max-width: 992px) {
    height: 250px;
  }
}

// toogle button
.toggle_container {
  text-align: center;
  margin-left: 20px;

}

.toggle-switch {
  position: relative;
  width: 85px;
  text-align: left;
  top: 2px;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  font-size: 12px;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 35px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.inner:before {
  content: "Read";
  padding-left: 15px;
  background-color: #060;
  color: #fff;
}

.inner:after {
  content: "Unread";
  padding-right: 3px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}

.switch {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-right: 8px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked+.label .inner {
  margin-left: 0;
}

.checkbox:checked+.label .switch {
  right: 0px;
}

.notification_btn_loader{
  padding: 8px 2rem;
  border: 4px solid #a0cafa;
  border-radius: 4px;
  background: #a0cafa;
  display: flex;
  align-items: center;
}

.notification-loading-progress {
  width: 1.25rem !important;
  height: 1.25rem !important;
}