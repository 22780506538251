.react-quill-container {
  height: 30vh;
}

.ql-toolbar.ql-snow {
  border-color: #dadada;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border: 2px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.ql-container.ql-snow {
  border: none;
}

.ql-editor {
  white-space: pre !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border: 2px solid #dadada;
  border-top: 1px solid #dadada;
}

.ql-tooltip {
  left: 1% !important;
}

.screen-links__grid_attachment {
  margin-top: 5%;
  border: 2px #dadada solid;
  border-radius: 0.625rem;
  padding: min(15px, 4%);
  transition: box-shadow 0.2s linear;
}
.screen-links__grid_attachment:hover {
  border-color: #a0a293;
}

.date__container {
  display: flex;
  justify-content: center;
}

.driver__badge__container {
  width: auto;
  height: 1.7rem;
  border-radius: 3px;
  padding: 0.2rem 0.3rem;
  background-color: #eeeeee;
  box-shadow: 1px 1px 2px #bbbbbb;
  margin: 0 !important;
}

.selected__drivers {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.metro__select__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.image_attachment {
  border: 2px #dadada solid;
  border-radius: 0.625rem;
  padding: min(200px, 3%);
  transition: box-shadow 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image_attachment:hover {
  border-color: #a0a293;
}