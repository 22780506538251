.calendar-button-container{
    width: 100%;
    border-top: 1px solid #eff2f7;
    padding: 0.5rem 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    gap: 1rem;
    text-align: center;
}