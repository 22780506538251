@import "../../../styles/_variables";

.screen-links {
  & &__form-wrapper {
    max-width: rem(700px);
    margin: 0 auto;
  }

  & &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(rem(300px), 1fr));
    gap: rem(20px);

    &-item {
      margin: 0;
      border: 1px $color-border solid;
      border-radius: $border-rad-md;
      padding: MIN(20px, 6%);
      transition: box-shadow 0.2s linear;

      &:hover {
        box-shadow: 0 0 rem(15px) hsla(0, 0%, 0%, 0.2);
      }
    }
  }

  & &__link {
    display: inline-flex;
    align-items: center;

    .icon {
      position: relative;
      top: rem(-2px);
      margin-left: rem(7px);
    }
  }
}
