.transaction__id__header {
  background-color: #4195f5 !important;
}

.main__detail__container {
  width: 90%;
  margin: 1% auto;
  background: #ffffff;
  box-shadow: 2px 11px 25px 6px rgba(234, 237, 241, 0.6);
  border-radius: 10px;
  padding: 1% 3%;
}

.detail__container {
  display: flex;
  justify-content: space-between;
  padding: 0.1% 0.5%;
}

.detail__name__container {
  width: 50%;
  padding: 0.5rem;
}

.details_name {
  font-weight: bold;
  margin-left: 3%;
}
.details_sublabel {
  font-weight: 300;
  margin-left: 3%;
}

.detail__description__container {
  width: 50%;
  padding: 0.5rem;
}
