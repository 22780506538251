@import '../../styles/_variables';

.screen-users .screen-users__form-body_logs {
  max-width: none;
}

.screen-users__logs-filter {
  input[type="date"] {
    &:invalid {
      border-color: $color-danger;

      &:focus {
        box-shadow: none;
      }

      ~ .btn {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.logs-filter {
  $self: &;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: rem(20px);

  & &__paginator-btn {
    align-items: center;
    gap: .2em;
    min-width: rem(85px);

    &:first-child {
      padding-right: .7em;
    }

    &:last-child {
      padding-left: .7em;
    }
  }

  & &__paginator-icon {
    font-size: 1.3em;
  }

  @media (max-width: $sm-) {
    gap: rem(10px);

    & &__paginator-btn {
      min-width: auto;

      #{$self}__paginator-text {
        display: none;
      }
    }
  }

  @media (max-width: $xs-) {
    & &__paginator-btn {
      min-width: auto;

      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 3;
      }

      #{$self}__paginator-text {
        display: none;
      }
    }

    & &__main-control {
      flex: 1 0 100%;
      order: 1;
    }
  }
}

.log-result-status {
  color: $color-solid;

  &#{&}_error {
    color: $color-danger;
  }
}


