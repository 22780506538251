@import "../../../styles/_variables";
@import "../../../styles/_mixins";

.table_drivers {
  tbody {
    & .table__td-content-car {
      display: flex;
      align-items: center;

      .icon-holder {
        display: inherit;
        align-items: inherit;
        justify-content: center;
        width: 1.5em;
        margin-right: 0.5em;

        &:empty {
          display: none;
        }
      }

      .icon {
        --shadow-offset: 1.5px;
        --shadow-offset-negative: calc(var(--shadow-offset) * -1);
        --shadow-color: #fff;
        font-size: 1.5em;
        color: $color-accent;
        filter: drop-shadow(
            0 var(--shadow-offset-negative) 0 var(--shadow-color)
          )
          drop-shadow(var(--shadow-offset) 0 0 var(--shadow-color))
          drop-shadow(0 var(--shadow-offset) 0 var(--shadow-color))
          drop-shadow(var(--shadow-offset-negative) 0 0 var(--shadow-color));

        &_large {
          font-size: 1.5em;
        }
      }
    }
  }

  .drivers__select-status-wrapper {
    width: rem(200px);
  }

  & &__details__btn {
    min-height: 1.8rem;
    width: 100%;
  }
}

.main_metrodropdown {
  width: 300px !important;
}

.phone_table_view{
  width: 110px;
  display: block;
}

.username_table_view{
  width: 153px;
  display: block;
}

.notes_table_view{
  display: block;
  width: 80px;
}

.main_searchbar {
  position: relative;
  width: inherit;
  display: flex;
}
.username_styling{
  display: flex;
  align-items: center;
  width: 130px;
  gap: 5px;
  justify-content: space-evenly;
}

.stepper {
  width: 200px;
}
.css-vnkopk-MuiStepLabel-iconContainer {
  padding-right: 0px !important;
}
#onboardingAt > .tooltip-inner {
  max-width: 100%;
}
#progress {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  position: relative;
  text-align: center;
  margin: 7px 0;

  @media (max-width: 1600px) {
    svg {
      width: 18px !important;
    }
  }

  .list::after {
    content: "";
    position: absolute;
    top: 12px;
    width: 12%;
    border: 1.5px solid #2196f3;
    background: gray;
    margin-left: 2px;
    z-index: 1;
  }

  .list2::after {
    content: "";
    position: absolute;
    top: 12px;
    width: 12%;
    border: 1.5px solid gray;
    background: gray;
    margin-left: 2px;
    z-index: 1;
  }

  .list3::after {
    content: "";
    position: absolute;
    top: 12px;
    width: 12%;
    border: 1.5px solid #E2991F;
    background: #E2991F;
    margin-left: 2px;
    z-index: 1;
  }
}

@media (max-width: 1440px) {
  .main_exportcvs {
    width: 90px;
  }

  .main_metrotxt {
    display: none !important;
  }

  .main_metrodropdown {
    width: 200px !important;
  }

  .main_searchbar {
    width: 150px;
  }
}

.filter_btn {
  width: 50px;
}
.filter_btn:hover .filterit {
  filter: invert(1) sepia(1) saturate(5) hue-rotate(170deg);
}

.filterit {
  filter: invert(0.4) sepia(1) saturate(5) hue-rotate(170deg);
}

@media (max-width: 1200px) {
  .filter_btn {
    width: 40px !important;
  }
}

.time .time-range .time-range__label_from {
  left: 10px !important;
}

.time .time-range .time-range__label_to {
  right: 10px !important;
}

@media only screen and (max-width: "1550px") {
  .infinit_table {
    height: 76vh !important;
  }
}