@import "../../../styles/_variables";
@import "../../../styles/_mixins";
@import "../../../styles/_helpers";
@import "../../../styles/_elements";

.MuiModal-root.MuiModal-root_not-clickable {
  //for avoid click to backdrop; further need replace by programly/settings
  .MuiBackdrop-root {
    pointer-events: none;
  }
}

.modal {
  $self: &;
  $box-shadow: 0 0 rem(10px) hsla(0, 0%, 0%, 0.15);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: $border-rad-lg;
  box-shadow: $box-shadow;
  background: #fff;
  width: 90%;
  max-width: rem(750px);

  &#{&}_wide {
    max-width: rem(900px);
  }

  &#{&}_lg-wide {
    max-width: 1200px;
  }

  &#{&}_full-width {
    max-width: none;
  }

  &#{&}_dialog {
    max-width: rem(500px);
    min-height: rem(100px);
    border: none;

    #{$self}__header {
      background-color: $color-accent;
      border-bottom: none;
      box-shadow: 0 -2px 0 0 $color-accent, $box-shadow;
    }

    #{$self}__title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem($font-size-regular);
      font-weight: 400;
      color: $color-text-invert;

      .icon {
        position: relative;
        top: rem(-1px);
        font-size: 1.3em;
        margin-right: 0.5em;
        opacity: 0.5;
      }
    }

    #{$self}__body {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 1.3em;
        margin-right: 0.5em;
        color: $color-accent;
      }
    }

    #{$self}__footer {
      border-top: none;
      padding-top: rem(10px);
    }
  }

  > div {
    padding: rem(12px);
  }

  & &__header {
    border-top-right-radius: $border-rad-lg;
    border-top-left-radius: $border-rad-lg;
    background-color: hsl(0deg, 0%, 95%);
    border-bottom: 1px $color-border solid;
  }

  & &__title {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: rem($font-size-heading);
    font-weight: 600;
  }

  & &__title-icon {
    opacity: 0.5;
    margin-right: 0.5em;
  }

  & &__body {
    flex: 1;
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    max-height: calc(100vh - 200px);
    overflow: auto;

    .form__item:not(.form__item_summary) {
      margin: 0 0 rem(10px);
    }
  }

  & &__footer {
    display: flex;
    justify-content: center;
    border-top: 1px $color-border solid;
    border-bottom-right-radius: $border-rad-lg;
    border-bottom-left-radius: $border-rad-lg;

    .btn {
      min-width: rem(100px);
      margin: 0 rem(5px);
    }
  }

  & &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.after-loading {
  max-width: 60.875rem;
  height: auto;
}

.when-loading {
  max-width: 60.875rem;
  height: 20rem;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  background: none !important;
  border-right: 2px solid #cacaca !important;
  border-top-right-radius: 0.075rem !important;
  border-bottom-right-radius: 0.075rem !important;
}

.react-tel-input .country-list .search-box {
  width: 310px !important;
  border: 2px solid #cacaca !important;
  border-radius: 0.375rem !important;
}

.PhoneInputInput {
  border: none;
  outline: none;
  padding-left: 0.2rem;
}

.margin__negative {
  margin-top: -0.4rem;
}

.driver_license{
  display: grid;
  align-items: baseline;
  margin: 0.5rem 0;
  grid-template-columns: 0.5fr 1fr;
}
.color-black div {
  color: #000 !important;
  font-size: 1rem;
}
.fw-600 {
  font-weight: 600;
}
::placeholder, #react-select-9-placeholder{
  font-size: 1rem;
  color: #000;
}