.optType {
  
    .MuiOutlinedInput-root {
      height: 2.625rem !important;
      width: 15.625rem;
      padding: 0px !important;
      padding-left: 9px !important;
    }
  
    fieldset {
      border: 2px solid #dadada !important;
    }
  }