@import 'https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Raleway:wght@100;200;300;400;500;600;700&family=Montserrat:wght@400;500;600;700&family=Mulish&display=swap';
@import 'reset.css';
@import '_variables';
@import '_mixins';
@import '_helpers';
@import '_elements';
@import '_typography';
@import '_forms';

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media (min-width: $xs) {
    font-size: 75%;
  }

  @media (min-width: $lg) {
    font-size: 75%;
  }

  @media (min-width: (($lg + $xl) / 2)) {
    font-size: 80%;
  }

  @media (min-width: $xl) {
    font-size: 90%;
  }

  @media (min-width: $xxl) {
    font-size: 100%;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  min-height: 100vh;
  color: $color-text;
  font-family: $ff-base;
  font-weight: 500;
  -webkit-font-smoothing: antialiased
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

*::selection {
  background-color: $color-accent;
  color: $color-text-invert;
}
