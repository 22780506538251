.main_contain {
    width: 650px;
}

.drawer_header {
    position: fixed;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: 650px;
    padding: 15px 25px;
    border-bottom: 1px solid #e4ebf4;
    background: #fff;
    align-items: center;
    z-index: 1;
}

.drawer_footer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 650px;
    padding: 20px 25px;
    border-top: 1px solid #e4ebf4;
    background: #fff;
    text-align: right;
    gap: "30px";
}

.panel-header {
    background-color: rgb(143, 137, 137);
    border-bottom: 1px solid ;
}

.accordionDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.toggle_box {
    border-radius: 2px;
    border: 1px solid #e3eaf3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    // margin-top: 23px;
    height: 45px;
    gap: 20px;
}

.dateRangePicker {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: -30px;
    box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.24);
}

.ranges {
    display: flex;
    justify-content: left;
    // gap: 20px;

        label {
            display: block;
        }

        input {
            width: 100px;
            border: 1px solid #e3eaf3;
            border-radius: 2px;
        }
        & input::-webkit-outer-spin-button,
        & input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
}

.column_Container {
    display:flex;
    justify-content: space-between;
    gap:30px;

    .showColumn, .hideColumn {
        width:100%;
        border: 2px solid #e3eaf3;
        
        h4 {
            font-size: 1rem;
            font-weight: normal;
            border-bottom: 1px solid #e3eaf3;
            padding:5px;
            text-align:  center;
        }
    }
}

.filter_modal {
    height: auto;
    left: 83%;
    width: 600px;
}

@media only screen and (max-width: "1200px") {
    .main_contain {
        width: 550px;
    }

    .drawer_header {
        width: 550px;
    }

    .drawer_footer {
        width: 550px;
    }
    .toggle_box {
        height: 40px !important;
    }
}

@media only screen and (max-width: "1440px") {
  .filter_modal {
    left: 78% !important;
    width: 580px;

  }
}

@media only screen and (max-width: "1024px") {
    .filter_modal {
      left: 73% !important;
      width: 500px;
  
    }
  }
