.backgroundC {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    font-size: 12px;
  }

  .toggle-switch-metros {
    position: relative;
    width: 100px;
    text-align: left;
    top: 2px;
  }
  
  .backgroundC:before,
  .backgroundC:after {
    float: left;
    width: 50%;
    height: 35px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  
  .backgroundC:before {
    content: "Activate";
    padding-left: 15px;
    background-color: #060;
    color: #fff;
  }
  
  .backgroundC:after {
    content: "Deactivate";
    padding-right: 10px;
    background-color: #ff0000;
    color: #fff;
    text-align: right;
  }

  .toggle_container_metros {
    text-align: center;
    margin-left: 0px;
  
  }

  .switch-metros {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 7px;
    margin-right: 8px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 66px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  
  .checkbox:checked+.label .backgroundC {
    margin-left: 0;
  }

  .checkbox:checked+.label .switch-metros {
    right: 0px;
  }
