body {
  margin: 0;

  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f9 !important;
  height: 100%;
}

body:not(.layout-fixed) .main-sidebar {
  height: 100%;
  min-height: auto;
  top: 0;
  padding-bottom: 1rem;
}
.options {
  display: flex;
  justify-content: flex-end;
  margin-right: 29px;
  gap: 0.4rem;
}

.align-right {
  text-align: right;
}

.page-link:disabled {
  color: grey;
}

.dropdown-item:focus {
  background-color: none;
}

.dropdown-menu-center {
  left: -120px !important;
}

.dropdown-item:hover {
  background-color: none;
}
.download-table-xls-button {
  background-color: rgb(87, 148, 248);
  border: none;
  color: white;
  padding: 0;
}
.download-table-xls-button:hover {
  background-color: rgb(87, 148, 248);
}
.download-table-xls-button:focus {
  background-color: rgb(87, 148, 248);
}

.menu {
  cursor: pointer;
}

.keatGV {
  overflow-x: inherit;
  overflow-y: inherit;
}
.btn:focus {
  box-shadow: none;
}
.available {
  margin-left: 88%;
}
.selected {
  margin-left: 88%;
  margin-top: 0.25rem;
}

.loading_image {
  margin-left: 10%;
}

#example2_filter {
  text-align: right;
}

.dropdown-toggle::after {
  margin-left: 0;
}
.form-control-sm {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
.modal {
  overflow: visible;
}
.empty_cell {
  background-color: #e68d83;
}

.modal-content {
  border: none;
}
.modal-backdrop.show {
  opacity: 0.2;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

#myInputTextField:focus {
  border: none;
  outline: none;
}

.w-12{
  width: 12% !important;
}
.w-16{
  width: 16% !important;
}
.w-17{
  width: 17% !important;
}

.w-14{
  width: 14% !important;
}

@media (max-width: 576px) {
  .options {
    display: flex;
    justify-content: flex-end;
    gap: 0.2rem;
    margin-right: 5%;
  }
  .available {
    margin-left: 25%;
  }
  .selected {
    margin-left: 25%;
    margin-top: 0.25rem;
  }
  .loading_image {
    height: 200px;
    width: 250px;
    margin-left: 10%;
  }
  .main-footer {
    margin-top: 50px;
  }
}
