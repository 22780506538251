.search__input {
  width: 100%;
  border: 2px solid #f2f2f2;
  border-radius: 7px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.5em 0.8em;
  outline: none;
}

.scroll__view {
  height: 30vh;
  display: flex !important;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-top: none;
  border-color: #f2f2f2;
  border-radius: 7px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow-y: scroll !important;
}

.inner__scroll__view__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.clear__btn {
  position: absolute;
  right: 1.5%;
  top: 20%;
  z-index: 10;
  transition: all 0.5s;
  cursor: pointer;
}

.driver__table {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem !important;

  & &__first__cell {
    width: 30%;
  }

  & &__middle__cell {
    width: 30%;
  }

  & &__seccond__cell {
    width: 20%;
  }

  & &__third__cell {
    width: 3%;
    text-align: center;
  }

  & &__last__cell {
    width: 17%;
    display: flex;
    justify-content: flex-end;
    gap:20px;
  }
}

.driver_name_badge {
  display: flex;
  margin-right: 10px;
  flex-direction: column;
}

.new__driver__badge {
  background-color: #cad7ff;
  color: #6d91ff;
  padding: 0.1rem 0.5rem 0;
  border-radius: 5px;
  font-weight: 600;
  display: inline-block;
  display: flex;
  align-items: center;
}

.icon__sm {
  font-size: 0.5rem;

  @media (max-width: "768px") {
    font-size: 0;
  }
}
