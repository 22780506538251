.dt__rng__pickr__cont {
  transform: scale(0.8);
  display: flex;
  flex-direction: column;
  z-index: 10000;
}

.date__range__picker {
  display: flex;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
  margin-left: -45px;
  margin-top: -40px;
  border: 1px solid #dadada;
  border-radius: 5px;
  width: 140%;
}
