@import '../../../styles/_variables';
@import '../../../styles/_mixins';
@import '../../../styles/_helpers';
@import '../../../styles/_elements';

.time-range {
  display: flex;
  font-size: 0.75rem;

  & &__span {
    width: em(40px, $font-size-min);
    position: relative;
    padding-top: em(20px, $font-size-min);
    border-bottom: 5px solid #00000033;

    &_active {
      border-bottom-color: $color-accent;
    }

    &_favorite {
      border-bottom-color: $color-favorie;
    }
  }

  & &__label {
    --translateX: 50%;
    position: absolute;
    bottom: 0;
    transform: translateX(var(--translateX));

    &_from {
      --translateX: -50%;
      left: 0;
    }

    &_to {
      right: 0;
    }
  }

  & &__tick {
    position: absolute;
    height: 11px;
    border-left: 1px solid black;
    transform: translateY(calc(3px - 50%));

    &_from {
      left: 0;
    }

    &_to {
      right: 0;

    }
  }
}

