@import '../../../styles/_variables';
@import '../../../styles/_mixins';
@import '../../../styles/_helpers';
@import '../../../styles/_elements';

.week-schedule {
  display: flex;
  font-size: 0.75rem;

  & &__day {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-accent;
    min-width: em(30px, $font-size-min);
    height: em(20px, $font-size-min);
    padding: em(2px, $font-size-min);
    opacity: 0.3;
    border-radius: 2px;
    color: $color-text-invert;

    &:not(:last-child) {
      margin-right: 2px;
    }

    &_active {
      opacity: 1;
    }

    &_favorite {
      background-color: #f9d;
    }
  }
}
