.option__wrapper__container {
  display: flex;
  align-items: center;
  white-space: nowrap !important;
}

.option_inner_wrapper{
  white-space: normal;
  display: flex;
}

.menu__label__text {
  font-size: 16px;
  font-weight: 300 !important;
  word-wrap: break-word;
}

.loading {
  font-weight: bolder;
  display: inline-block;
  font-family: monospace;
  color: #a0a293;
  font-size: 22px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

.loading__text {
  color: #a0a293;
  font-size: 14px;
  letter-spacing: 0.09em;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.bounce {
  outline: 0;
  border-color: red;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(10px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-10px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(8px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-8px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
