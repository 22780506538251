@import "../styles/_variables";
@import "../styles/_mixins";
@import "../styles/_helpers";
@import "../styles/_elements";

.filter {
  width: 100%;
  position: relative;
  z-index: 10;

  & &__container {
    border: 1px $color-border solid;
    border-top: none;
    background: #fff;
  }

  & &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: rem(20px);
    padding: rem(8px) 0;
  }

  & &__ctrls-holder {
    flex: 1 0 MIN(100%, rem(400px));
  }

  & &__input-holder {
    display: flex;
    align-items: center;
    gap: rem(10px);

    &:focus-within {
      .filter__search-icon {
        opacity: 0.7;
      }
    }
  }

  & &__search-icon {
    font-size: rem(25px);
    opacity: 0.3;
    flex-shrink: 0;
  }

  & &__sets-holder {
    position: relative;
    top: rem(2px);
    display: flex;
    flex-wrap: wrap;
    column-gap: rem(15px);
    row-gap: rem(3px);
  }

  .btn {
    min-width: rem(80px);
  }

  .form__input {
    width: auto;
    flex: 1 1 auto;
  }
}

.dataLayout {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__content-wrapper {
    display: flex;
    flex: 1;
    overflow: auto;
    position: relative;
  }

  &__container {
    flex: 1 0 100%;
    position: relative;
    padding: 0;
  }

  &__container-inner {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    padding-bottom: 3px;
  }

  .table {
    thead {
      th {
        border-top: none;
      }
    }
  }
}

.table-wrapper {
  height: 80vh;
  overflow: auto;
}

.table {
  $self: &;
  width: 100%;
  table-layout: auto;
  font-size: rem($font-size-regular - 3px);
  margin-bottom: 0.1rem;

  th,
  td {
    border: 1px $color-border solid;
    padding: 0.2em 0.7em;
    line-height: 1.3;
    vertical-align: middle;

    &.table__th-time {
      width: rem(120px);
    }
  }

  &#{&}_thead-sticky {
    thead {
      position: sticky;
      top: -1px;
      z-index: 5;
      border-bottom: 1px $color-border solid;

      th {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 1px;
          background: $color-border;
        }
      }
    }
  }

  &#{&}_sortable {
    thead {
      th {
        height: rem(50px);
        min-width: rem(55px);
        padding: 0;
      }
    }

    #{$self}__th-sorter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.7em 0.2em 0.7em 0.5em;
      block-size: 100%;

      .icon {
        color: $color-text-sec;
        margin: -2px 0;
        font-size: 0.9em;
      }

      &.sort-asc {
        .icon-desc {
          display: none;
        }
      }

      &.sort-desc {
        .icon-asc {
          display: none;
        }
      }

      &:not(:empty) {
        cursor: pointer;

        &:hover {
          background-color: hsl(0deg, 0%, 93%);
        }

        &:active {
          background-color: hsl(0deg, 0%, 90%);
        }
      }
    }

    #{$self}__sort-dir {
      position: relative;
      top: -1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: rem(5px);
    }
  }

  &#{&}_tr-clickable {
    tbody {
      tr {
        cursor: pointer;
        transition: background-color 0.3s linear;

        &:hover {
          background-color: hsl(0deg, 0%, 90%);
        }
      }
    }
  }

  &#{&}_striped {
    tbody {
      tr {
        &:not(#{$self}__tr-danger):not(#{$self}__tr-active):nth-child(even)
          > td {
          background-color: hsl(0deg, 0%, 97%);
        }
      }
    }
  }

  thead {
    th {
      background-color: $color-extra-mute;
      font-weight: 600;

      #{$self}__th-sublabel {
        display: block;
        margin-top: 0.3em;
        font-weight: 500;
        font-size: $font-size-min;
      }
    }
  }

  tbody {
    tr {
      position: relative;
      z-index: 4;

      &::after {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        bottom: -1px;
        transition-property: border-color, background-color;
        transition-duration: 1s;
        transition-timing-function: ease;
        border: 2px solid transparent;
        background-color: transparent;
        pointer-events: none;
      }

      &#{$self}__tr-danger {
        background-color: lighten($color-danger, 40%);

        td {
          position: relative;
          color: mix($color-text, $color-danger, 70%);
          border-color: lighten($color-danger, 30%);

          &::before {
            content: "";
            position: absolute;
            top: -1px;
            right: 0;
            left: 0;
            background-color: lighten($color-danger, 30%);
            height: 1px;
          }
        }
      }

      &#{$self}__tr-active {
        z-index: 5;
        &::after {
          border-color: $color-accent;
          background-color: fade-out($color-accent, 0.8);
        }
      }
    }

    td {
      &#{$self}__td_have-btn {
        width: 6em;
      }

      .btn {
        font-size: inherit;
        cursor: pointer;
      }

      &#{$self}__td_centered {
        text-align: center;
      }

      &#{$self}__td_nowrap {
        white-space: nowrap;
      }

      &#{$self}__td_has-checkbox {
        padding-right: rem(5px);
        padding-left: rem(5px);

        input[type="checkbox"] {
          margin-right: 0;
        }
      }

      &#{$self}__td_min-vert-padding {
        padding-top: rem(5px);
        padding-bottom: rem(5px);
      }

      &#{$self}__td_min-horiz-padding {
        padding-right: rem(5px);
        padding-left: rem(5px);
      }

      #{$self}__td-content-sub-text {
        font-size: $font-size-min;
      }

      a:not(.btn) {
        color: $color-text;
        text-decoration: underline;

        &:hover {
          color: $color-accent;
        }
      }
    }
  }
  @media screen and (max-width: $xxl) {
    font-size: rem($font-size-min + 2px);
  }
}

.loading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  min-height: 70vh !important;
}

.loading-progress {
  width: rem(30px) !important;
  height: rem(30px) !important;
}

.no_results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  min-height: 70vh;
  color: $color-text-sec;
}

.status-in-table {
  $self: &;

  & &__icon-holder {
    flex-shrink: 0;
    width: rem(30px);
    display: flex;
    align-items: center;
  }

  & &__icon {
    font-size: 1.4em;
    color: hsl(0, 0%, 60%);

    &_created {
      & ~ #{$self}__addition-icon {
        font-size: 1em;
        color: $color-accent;
      }
    }

    &_assigned {
      & ~ #{$self}__addition-icon {
        color: $color-success;
      }
    }

    &_rejected {
      & ~ #{$self}__addition-icon {
        font-size: 1.1em;
        color: $color-danger;
      }
    }

    &_delivery-started {
      position: relative;
      top: rem(1px);
      left: rem(-2px);
      font-size: 1.75em;

      & ~ #{$self}__addition-icon {
        right: rem(-1px);
        bottom: rem(3px);
        color: $color-accent;
      }
    }

    &_complete {
      font-size: 1.3em;
      color: $color-success;
    }

    &_paid {
      & ~ #{$self}__addition-icon {
        bottom: rem(1px);
        color: $color-success;
      }
    }
  }

  & &__icon-group {
    position: relative;
    top: rem(1px);
  }

  & &__addition-icon {
    $shadow-offset: -2px;
    $shadow-color: #fff;
    font-size: 0.9em;
    position: absolute;
    right: rem(-3px);
    bottom: 0;
    filter: drop-shadow(0 $shadow-offset 0 $shadow-color)
      drop-shadow($shadow-offset 0 0 $shadow-color);
  }

  & &__text {
    @media (min-width: $lg) {
      white-space: nowrap;
    }
  }
}

.time-output {
  display: flex;
  justify-content: center;
  gap: rem(5px);

  & &__sign {
    font-size: $font-size-min;
  }
}

.filter .search__container {
  flex: unset !important;
}

.card {
  margin-bottom: unset;
}

@media (max-width: 1830px) {
  .main__driver {
    display: flex;
    align-items: center;
  }

  .data__text {
    top: 60% !important;
  }

  .main__driver .card-title {
    margin-bottom: 0.75rem;
  }
}

.refresh_btn {
  border: 2px solid #dadada;
  padding: 6px 7px;
  border-radius: 8px;
  background-color: #fff;
  color: #3396f3;

  .refresh_icon {
    font-size: 2rem;
  }

  &:hover {
    background-color: #3396f3;
    color: #fff;
    border: 2px solid #3396f3;
  }

  &:focus {
    outline: none;
  }
}

@media (max-width: 1450px) {
  .table {
    font-size: rem($font-size-regular - 4px) !important;
  }  
}