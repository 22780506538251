.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-top: 2px !important;
  padding-right: 4px !important;
  padding-bottom: 0px !important;
  padding-left: 6px !important;
}

.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 9px;
  height: 45px !important;
}

.metro_select_div{
  display: flex;
  flex-direction: column;
  position: relative;
}

.metro_select_label{
  background-color: white;
  position: absolute;
  top: -7px;
  left: 10px;
  font-size: 11px;
  z-index: 2;
  color:  rgb(0, 0, 0 , 0.6);
  font-weight: 600;
}