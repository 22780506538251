.rating_root {
  width: 100%;
  margin-bottom: 20px;

  .card {
    margin: 0 auto;
    margin-top: 10px;
  }
  .bigBox {
    display: block;
    width: 100%;
    padding: 20px;

    .outerAverageBox {
      display: float;
      float: left;
      width: 20%;
    }

    .averageBox {
      display: float;
      float: center;
      text-align: center;
      white-space: nowrap;
      padding-top: 10px;
    }
    .average {
      font-size: 44px;
      line-height: 44px;
      font-weight: 100;
    }

    table {
      width: 100%;
      table-layout: fixed !important;
      padding: 1px;

      .td {
        width: 40px;
        text-align: right;
      }
      .count {
        color: #000000;
        padding-left: 10px;
      }
    }
  }
}
.count_badge {
  border-radius: 5px;
  padding: 5px 8px;
  text-align: center;
  width: auto;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-transform: capitalize;
  background-color: #e7fae3;
  color: #2d6040;
  border: 1px solid #e7fae3;
}
