.inputData {
  .textarea {
    border: 2px solid #dadada;
    &:hover {
      border: 2px solid #a0a293 !important;
    }
  }
}

.rating_contain {
  .css-ryrseu-MuiRating-root {
    font-size: 20px !important;
  }
}

.single-date-select {
  .rdrCalendarWrapper {
    font-size: 16px;
  }

  .shift_date-placeholder {
    color: #b5b3b3;
  }
}

.date-range-button {
  width: 100%;
  border-top: 1px solid #eff2f7;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  gap: 2rem;
  text-align: center;
}

.attachment__wrapper {
  border: 2px solid #dadada;
  border-radius: 0.375rem;
  width: 100%;
  padding: 10px;

  .attachment_btn {
    color: #828f9a;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin: 0;
    cursor: pointer;

    .opt_icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }
}

.link_input {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #dadada;
  &:focus {
    outline: none;
  }
  width: 100%;
  &::placeholder {
    color: #a2a2a2;
  }
}


//rating style
.inner:before {
  content: "seen" !important;
  padding-left: 15px;
  background-color: #060;
  color: #fff;
}

.inner:after {
  content: "Unseen" !important;
  padding-right: 3px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}