.header__helper {
  display: flex;
  flex-wrap: wrap;
}

.data__text {
  position: absolute;
  top: 70%;
  color: #4d4d4d;
  font-size: 0.8rem;
  font-weight: 600;
}
