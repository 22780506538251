@import "../../../styles/_variables";
@import "../../../styles/_mixins";

.table_orders {
  $self: &;

  thead {
    tr {
      #{$self}__th_checkbox {
        min-width: auto;
        width: rem(24px);
      }

      #{$self}__th_metro {
        width: rem(130px);
      }
    }
  }

  & &__loading-time-range {
    white-space: nowrap;
  }

  & &__driver-phone {
    white-space: nowrap;
  }
}

.shift_main_div {
  .col-md-2 {
    max-width: 6% !important;
  }

  .col-lg-10 {
    max-width: 100% !important;
    flex: 0 0 92% !important;
  }
}

.shift_header {
  height: 42px !important;
}

.shift_select_div {
  display: flex;
  flex-direction: column;
  position: relative;

  .select_label {
    background-color: white;
    position: absolute;
    top: -7px;
    left: 10px;
    font-size: 11px;
    z-index: 2;
    color: rgb(0, 0, 0, 0.6);
    font-weight: 600;
  }
}

.select-date-range-container {
  height: 45px;
  width: 350px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 0.8rem;
}

.mainDateRangePicker {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  right: 5%;
  top: 100%;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
}

.button-container {
  width: 100%;
  border-top: 1px solid #eff2f7;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  gap: 3rem;
  text-align: center;
}

.cancelButton {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  border-radius: 7px;
  text-transform: uppercase;
  color: white;
  background-color: red;
  width: 32%;
  text-align: right;
}

.cancelButton:hover {
  background-color: #ffffff;
  color: red;
  border: 2px solid red;
  transition: all 0.5s;
}

.okButton {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #3d91ff;
  border-radius: 7px;
  text-transform: uppercase;
  color: white;
  background-color: #3d91ff;
  width: 32%;
  text-align: right;
}

.okButton:hover {
  background-color: #ffffff;
  color: #3d91ff;
  border: 2px solid #3d91ff;
  transition: all 0.5s;
}

.clearButton {
  border: 1px solid gray;
  color: white;
  background-color: gray;
}

.clearButton:hover {
  color: red;
  border: 2px solid red;
}

.mainCheckbox {
  margin-left: 0.65rem;
  margin-bottom: 1.5rem;
}

.orders-input-checkbox {
  text-align: center;
}

.card__header__container {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  line-height: 3;
  flex-wrap: wrap;
}

.metro_div {
  .auto__complete {
    height: 45px !important;
    font-size: 1px !important;
  }

  .MuiAutocomplete-input {
    font-size: 14px !important;
  }
}

.status_filter {
  button {
    &:focus {
      outline: none !important;
    }
  }
}

@media only screen and (max-width: "1565px") {
  .filter__label {
    display: none !important;
  }

  .new__shift__btn {
    height: 2.9rem !important;
  }
}

@media only screen and (max-width: "1375px") {
  .auto__complete {
    width: 200px !important;
  }
}

.icon_danger:hover {
  color: #ffffff
}

.btn_cancel {
  border: 2px solid #f5252a !important;
  color: #f5252a !important;
}

.btn_cancel:hover {
  background-color: #f5252a !important;
}


@media only screen and (max-width: "1550px") {
  .shift_table {
    height: 70vh !important;
  }
}
