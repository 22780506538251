.feedback_contain {
  width: 900px;

  label {
    margin-bottom: 5px;
    color: rgb(131, 131, 131);
    display: block;
  }

  .toggle_box {
    border: 2px solid #e4ebf4;
    border-radius: 5px;
  }
}

.drawer_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px;
  border-bottom: 2px solid #e4ebf4;
  position: fixed;
  height: 80px;
  top: 0px;
  background-color: #fff;
  z-index: 1000;
}

.drawer_head_left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.drawer-title {
  font-size: 30px;
}

.drawer__footer {
  display: flex;
  justify-content: right;
  gap: 10px;
  align-items: center;
  padding: 0px 25px;
  border-top: 1px solid #e4ebf4;
  position: fixed;
  height: 80px;
  bottom: 0px;
  background-color: #fff;
  z-index: 10;

  button {
    padding: 10px;
  }
}

.feature {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #e3eaf3;
  &:focus {
    outline: none;
  }
}

.inputData {
  textarea {
    @extend .feature;
    width: 100%;
    &::placeholder {
      color: #a2a2a2;
    }
  }

  .MuiOutlinedInput-root {
    height: 50px !important;
    padding: 0px !important;
    padding-left: 9px !important;
  }

  fieldset {
    border: 2px solid #e3eaf3 !important;
  }

  .select_multi {
    label {
      color:unset !important;
    }
  }
}

.options_wrapper {
  border: 2px solid #e3eaf3;
  margin: 20px 0px;
  padding: 10px;
  border-radius: 5px;
}

.feedback_contain .options_detail {
  input {
    @extend .feature;
    padding: 6px 10px !important;
    &::placeholder {
      color: #a2a2a2;
    }
  }

  .option_btn {
    color: #828f9a;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin: 0;
    cursor: pointer;

    .opt_icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }
  .badge {
    display: flex;
    background: rgba(65, 149, 245, 0.2);
    border: 1px solid #9fc1e8;
    padding: 10px 16px;
    color: #4195f5;
    font-size: 16px;
    border-radius: 5px;

    .clear_badge {
      font-size: 16px;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}

.feedback_contain .daterange {
  border: 2px solid #e3eaf3 !important;
}

.feedback_answer {
  border: 2px solid #e3eaf3 !important;

  legend {
    font-size: 16px;
    color: rgb(131, 131, 131);
    font-weight: 600;
  }
  input {
    border-color: #e3eaf3;
  }
  .scroll__view {
    border-color: #e3eaf3;
    overflow-y:auto !important ;
    .driver__table {
      border-bottom: 2px dashed #e3eaf3;

      .feedback__table__first__cell {
        width: 30%;
      }
      .feedback__table__middle__cell {
        width: 58%;
      }
      .feedback__table__last__cell {
        width: 12%;
        text-align: center;
      }
    }
  }
}
