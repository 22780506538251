.mainAdPayCheckbox {
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}

.mainShiftPayCheckbox {
  margin-left: 0.5rem;
  margin-bottom: 1.5rem;
}

.shift-input-checkbox {
  display: flex;
  justify-content: center;
  padding-top: 0.2rem;
}
div#demo-simple-select-required {
  width: 100%;
}



.H-2 {
  height: 2.5rem;
}

.label_container {
  display: "flex";
  flex-direction: "column";
  margin-right: "10px";

  .label {
    border-radius: 5px !important;
    padding: 4px;
    font-weight: 600;
    white-space: nowrap;
    text-transform: capitalize;
    background-color: rgb(231, 250, 227);
    color: rgb(46, 66, 53);
    border: 1px solid rgb(231, 250, 227);
    font-size: 13px;
  }

  .label_yellow {
    margin-top: 5px;
    background-color: rgb(245, 242, 207);
    color: rgb(66, 63, 48) !important;
    border: 1px solid rgb(245, 242, 207);
  }

}

.label-date-picker{
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0px;
  top: -20px;
  z-index: 1;
  background: #fff;
}