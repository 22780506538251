@import "../../../../styles/variables";

.table__scheduling {
  border: none;
  vertical-align: center;
  border-collapse: unset;
  font-size: 0.8rem;
  height: 70vh;
  white-space: nowrap;

  @media (max-width: 1600px) {
    width: 140%;
  }

  @media (max-width: 1400px) {
    width: 160%;
  }

  @media (max-width: 1100px) {
    width: 180%;
  }

  @media (max-width: 700px) {
    width: 200%;
  }

  &__sticky__header {
    position: sticky;
    top: 0px;
    z-index: 5;
    border-bottom: 1px $color-border solid;
    background-color: white;
  }
}

.th__layout {
  border: 1px solid rgba(217, 217, 217, 0.5);
  filter: drop-shadow(0px 6px 30px rgba(208, 207, 223, 0.3));
  border-radius: 10px;
  position: relative;

  &__dt__txt {
    display: block;
    margin: auto;
    text-align: center;
    padding: 1rem 0;
    word-wrap: break-word;
    width: 60%;

    &__day {
      font-weight: 600;
    }
  }
}

.sticky__name {
  position: sticky;
  left: 0;
  z-index: 1000;
  background-color: #ffffff;
}

.td__layout {
  border: 1px solid rgba(40, 48, 66, 0.25);
}

.details__cont {
  width: 100%;
  border-radius: 3px;
  padding: calc($px-size_xxs - 5px);
  color: #283042;
  margin-bottom: 0.15rem;
  line-height: 1.1;
  white-space: nowrap;

  &__name {
    display: inline-block;
    width: 60%;
  }

  &__data {
    text-align: right;
    display: inline-block;
    width: 40%;
  }
}

.metro__name__blck {
  width: 100%;
  height: 60%;
  text-align: center;
  display: flex;
  padding: unset;

  &__txt {
    font-weight: 600;
    opacity: 0.8;
    white-space: normal;
    cursor: pointer;
  }
}

.bg {
  &__assigned {
    background: #ffe7e4;
  }

  &__blocks {
    background: #d7f4ea;
  }

  &__defitieted {
    background: #dfdaeb;
  }

  &__pipeline {
    background: #e6f3ff;
  }

  &__interested {
    background: #fcefe3;
  }

  &__actual {
    background: #d4dfff;
  }

  &__new {
    background: #fff0f8;
  }

  &__shiftCount {
    background: #fcf1cf;
  }
}

/* DRIVERS VIEW SECTION */

.icon {
  transition: transform 3s !important;

  &__up {
    transform: rotate(180deg);
    color: #4d98ff;
  }

  &__down {
    transform: rotate(0deg);
  }
}

.inln_blck {
  display: inline-block;
}

.arrow___btn {
  border: none;
  background-color: transparent;
  outline: none !important;
}

.driver__table__thead {
  font-size: smaller;
  padding: 0;

  &__tr {
    width: 100%;
    display: flex;
    align-items: center;
    margin: auto 0;

    &__name {
      width: 52%;
    }

    &__isNew {
      width: 24%;
    }
    &__scheduled {
      width: 24%;
    }
  }
}

.name__container {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.data__container {
  background-color: #f7f7f7;
}

.table__data {
  padding: 2px;
  margin: auto;
  text-align: center;
}

@media only screen and (max-width: "1550px") {
  .scheduling_main_div {
    height: 78vh !important;
  }
}
