/*** applied to all status from here ***/
.textStyleForStatus {
  text-transform: capitalize;
}

.align-center {
  vertical-align: middle !important;
}

.w-3 {
  width: 3% !important;
}
.w-4 {
  width: 4% !important;
}
.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}
.w-9 {
  width: 9% !important;
}


.w-10 {
  width: 10% !important;
}
.w-11 {
  width: 11% !important;
}

.w-13 {
  width: 13% !important;
}
.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}
.w-16 {
  width: 16% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.customized-details-btn {
  font-size: 85% !important;
  padding: 0.1rem 0.5rem 0 !important;
  min-height: 2rem !important;
}

.driver-operator {
  margin: 15px;
}

.approved__tag__name {
  font-size: 0.95rem;
}

.approved__date {
  font-size: 0.7rem;
}

.arrive-driver {
  background-color: green;
  width: 9px;
  min-width: 9px;
  height: 9px;
  margin-right: 10px;
  border-radius: 50%;
}

.not-arrive-driver{
  background-color: red !important; 
}

.align-drivername {
  display: flex;
  align-items: center;
}

.driver-arrive-at {
  display: grid;
  font-size: 10px;
}