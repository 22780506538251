.coaching_contain {
  width: 800px;

  label {
    margin-bottom: 5px;
    color: rgb(131, 131, 131);
    display: block;
  }

  .feature {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #e3eaf3;
    &:focus {
      outline: none;
    }
  }

  .inputData {
    .order_input {
      @extend .feature;
      width: 100%;
      &::placeholder {
        color: #a2a2a2;
      }
    }

    textarea {
      @extend .feature;
      width: 100%;
      height: 150px;
      &::placeholder {
        color: #a2a2a2;
      }
    }
  }
  
}

.image-select {
  border: 1px solid #cfd7e1;
  cursor: pointer;
  width: 160px;
  height: 150px;

  .select_img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-size: 16px;
      text-align: center;
    }
  }
}

.remove_btn {
  background-color: #4195f5;
  border: 2px solid #4195f5;
  color: #fff;
  padding: 4px;
  width: 70px;
  border-radius: 0.375rem;
}

.pdf {
  border: 1px solid #e5e5e5;
  // width: 150px;
  
  padding: 0px 10px;
  background-color: #f5f5f5;

  a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 50px;
  } 
  .pdf_icon {
    font-size: 30px;
    color: red;
    margin-right: 10px;
  }
}
