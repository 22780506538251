.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding-top: 2px !important;
    padding-right: 4px !important;
    padding-bottom: 0px !important;
    padding-left: 6px !important;
  }
  
  .css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 9px;
    height: 45px !important;
  }
  