@import "../../../../styles/_variables";
@import "../../../../styles/_mixins";
@import "../../../../styles/_helpers";
@import "../../../../styles/_elements";

.order-condition {
  display: table;
  margin: 0;
  padding: rem(5px) 0 0 0;
  list-style: none;

  & &__item {
    display: table-row;

    &:first-child ~ .order-condition__item > .order-condition__item-cell {
      border-top: 1px hsl(0deg, 0%, 80%) dashed;
    }
  }

  & &__item-cell {
    display: table-cell;
    padding: rem(2px) rem(5px);
  }

  & &__item-cell_title {
    font-weight: 700;
  }

  & &__item-cell_value {
    text-align: right;
  }
}

.available-drivers {
  $self: &;
  display: table;
  width: 100%;

  & &__row {
    display: table-row;
    width: inherit;

    &:first-child ~ #{$self}__row {
      #{$self}__cell {
        border-top: 1px $color-border dashed;
      }
    }
  }

  & &__cell {
    padding: 0.5em 0.7em;
    display: table-cell;
    vertical-align: middle;

    &_name {
      font-weight: 600;

      .icon {
        position: relative;
        top: rem(-1px);
        font-size: 1.3rem;
      }
    }
  }

  & &__inner {
    display: flex;
    align-items: center;
  }

  & &__icon-holder {
    display: inherit;
    align-items: inherit;
    justify-content: center;
    width: rem(25px);
    margin-right: rem(8px);

    .icon {
      font-size: 1.3em;
      color: $color-accent;

      &_car {
        font-size: 1.5em;
      }
    }
  }

  & &__text {
    flex: 0 1 auto;
    line-height: 1.1;
  }

  .btn {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.order-status {
  & &__btns {
    display: flex;
    flex-wrap: wrap;
    gap: rem(10px);

    &:empty {
      display: none;
    }

    .btn {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}

.order-copy-icon {
  position: relative;
  top: 0.05em;
  margin-left: 0.3em;
  opacity: 0.6;
  transition: all 0.2s linear;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(1.4);
    opacity: 1;
  }
}

.form__row {
  justify-content: space-between;
  width: 100%;

  &.form__row_brief-and-qr {
    justify-content: center;
  }

  .form__col {
    margin: 0 auto;

    &.form__col_brief {
      flex: 0 1 fit-content;
    }

    &.form__col_qr {
      flex: 1 0 rem(170px);
      max-width: 250px;

      .qr-code-holder {
        margin-top: rem(3px);

        @media (min-width: $sm) {
          max-width: rem(170px);
          margin-left: auto;
        }

        @media (hover) {
          position: relative;
          z-index: 10;
          transition: transform 0.5s linear;
          transform-origin: top right;
          background: #fff;

          &::before {
            --padding: -10px;
            --box-shadow-opacity: 0;
            content: "";
            position: absolute;
            top: var(--padding);
            right: var(--padding);
            bottom: var(--padding);
            left: var(--padding);
            border: calc(-1 * var(--padding)) #fff solid;
            border-radius: 5px;
            opacity: 0;
            transition: all 0.5s linear;
            transition-property: opacity, box-shadow;
            box-shadow: 0 1px 5px hsla(0, 0%, 0%, var(--box-shadow-opacity));
          }

          &:hover {
            transform: scale(1.6);

            &::before {
              --box-shadow-opacity: 0.25;
              opacity: 1;
            }
          }
        }

        > svg {
          margin-bottom: rem(-7px);

          > svg {
            display: contents;
          }
        }
      }

      .qr-code-source {
        margin-top: rem(10px);
        text-align: center;
        word-break: break-all;
        font-size: rem(14px);
      }
    }
  }
}

.reject__driver__container {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
  padding: 0.3rem;
}

.driver__name__text {
  width: 75%;
  margin: auto 0.3rem auto 0;
  word-wrap: break-word;
}

.reject__btn {
  width: 25%;
  font-size: 85% !important;
  padding: 0 !important;
  min-height: 1.8rem !important;
}

.extra {
  font-size: smaller;
}

.date {
  font-size: 9px;
}

.btn__sm {
  font-size: 85% !important;
  padding: 0 1.5rem !important;
  min-height: 2.3rem !important;
}
