.transaction-id-text {
  color: #4195f5;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
}

.transaction-id-text:hover {
  color: #137cf5;
  transition: all 0.5s;
}

.customized__input {
  width: 100% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
  padding-right: 1.7rem !important;
}
.searchbar__container {
  position: relative;
  width: inherit;
  display: flex;
}

.search__button {
  padding: 0px 10px;
  background: #2196f3;
  color: white;
  font-size: 17px;
  border: none;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  outline: none;
  cursor: pointer;
}

.table_fixed{
  table-layout: fixed !important;
}
