@import "../../../../styles/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  &__title {
    margin-bottom: 0;
  }

  &__dt__cont {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: #ffffff;
    padding: 0.5rem;
    box-shadow: 0px 6px 30px rgba(192, 190, 222, 0.3);
    border-radius: 10px;
    color: rgba(72, 87, 110, 0.5);

    &__icon {
      cursor: pointer;
      background: rgba(217, 217, 217, 0.4);
      border-radius: 50%;
      padding: 0.1rem;
      border: none;
    }

    &__range__picker {
      cursor: pointer;
    }
  }
}
