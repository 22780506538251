@import '../../styles/_variables';
@import '../../styles/_mixins';
@import '../../styles/_helpers';
@import '../../styles/_elements';

.screen_confirmation {
  position: relative;
}

.confirmation-bg {
  $grad: linear-gradient(90deg, #000 0, #000 80%, transparent 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: MIN(100%, MAX(400px, MIN(50%, 600px)));
  -webkit-mask-image: $grad;
  mask-image: $grad;
  pointer-events: none;
}

.container_confirmation {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(20px);
}

.confirmation-logo-wrapper {
  transform: translateY(-30%);
}

.confirmation-logo {
  font-size: 15em;
}

.confirmation {
  flex: 0 0 rem(500px);
  display: flex;
  align-content: flex-start;
  align-items: center;
  gap: rem(15px);
  flex-wrap: wrap;

  & &__icon-holder {
    display: inline-flex;
    flex-grow: .5;
    margin-left: auto;
  }

  & &__icon {
    font-size: 2em;
    color: $color-accent;

    &_error {
      color: $color-danger;

      & ~ * {
        color: darken($color-danger, 20%);
      }
    }
  }

  & &__text {
    flex-grow: 1;
    text-align: center;
    font-size: 2em;
  }
}
