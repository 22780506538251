.btn {
    font-weight: normal !important;
}

.btn:hover {
    background-color: #4195f5 !important;
    color: white !important;
    border-color: #4195f5;
}

.btn svg {
    margin-right: 10px;
}

.btnicon svg {
    fill: #92969c !important;
}

.btnicon svg:hover {
    fill: #a0cafa !important;
}