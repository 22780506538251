@import "../../../styles/_variables";
@import "../../../styles/_mixins";

.table_orders {
  $self: &;

  thead {
    tr {
      #{$self}__th_checkbox {
        min-width: auto;
        width: rem(24px);
      }

      #{$self}__th_metro {
        width: rem(130px);
      }
    }
  }

  & &__loading-time-range {
    white-space: nowrap;
  }

  & &__driver-phone {
    white-space: nowrap;
  }
}

.select-date-range-container {
  height: 45px;
  width: 350px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 0.8rem;
}

.DateRangePicker {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10000 ;
  right: 5%;
  top: 100%;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
}

.button-container {
  width: 100%;
  border-top: 1px solid #eff2f7;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  gap: 3rem;
  text-align: center;
}

.cancelButton {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  border-radius: 7px;
  text-transform: uppercase;
  color: white;
  background-color: red;
  width: 32%;
  text-align: right;
}

.cancelButton:hover {
  background-color: #ffffff;
  color: red;
  border: 2px solid red;
  transition: all 0.5s;
}

.okButton {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #3d91ff;
  border-radius: 7px;
  text-transform: uppercase;
  color: white;
  background-color: #3d91ff;
  width: 32%;
  text-align: right;
}

.okButton:hover {
  background-color: #ffffff;
  color: #3d91ff;
  border: 2px solid #3d91ff;
  transition: all 0.5s;
}

.clearButton {
  border: 1px solid gray;
  color: white;
  background-color: gray;
}

.clearButton:hover {
  color: red;
  border: 2px solid red;
}

.mainCheckbox {
  margin-left: 0.65rem;
  margin-bottom: 1.5rem;
}

.card__header__container {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  line-height: 3;
  flex-wrap: wrap;
}

.auto__complete {
  height: 45px !important;
}

@media only screen and (max-width: "1565px") {
  .filter__label {
    display: none !important;
  }

  .new__shift__btn {
    height: 2.9rem !important;
  }
}

@media only screen and (max-width: "1375px") {
  .auto__complete {
    width: 200px !important;
  }
}


.dhx_header-wrapper {
  z-index: 1;
}
.dhx_footer-wrapper {
  z-index: 0;
}

.alternate_row .dhx_grid-row:nth-child(2n) {
  background: #f8f8f8;
}

.commonStyle  {
  border-radius: 5px;
  padding: 2px 4px;
  text-align: center;
  width: auto;
  font-size: 80%;
  font-weight: 500;
  white-space: nowrap;
  text-transform: capitalize;
};

.approved  {
  background-color: #E7FAE3;
  color: #2D6040;
  border: 1px solid #E7FAE3;
};

.rejected {
  background-color: #F5CFD3;
  color: #671A21;
  border: 1px solid  #F5CFD3;
};
 .created {
  background-color: #F5F2CF;
  color: #6B5C1F;
  border: 1px solid #F5F2CF;
};
.pending {
  background-color: gray;
  color: #FFFFFF;
  border: 1px solid gray
};

.interested {
  background-color: #cad7ff;
  color: #6d91ff;
  border: 1px solid #cad7ff;
};