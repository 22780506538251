@import "../../styles/variables";

.brand-link {
  margin-bottom: 1rem;
}

.w-95 {
  width: 95% !important;
}

.brandlogosidebar {
  display: block;
  margin: auto;
  width: 100%;
}

.nav_link {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  color: #fff;
  text-decoration: none !important;
  // padding: 0.5rem 1rem;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  overflow: hidden;
}

.nav_link:hover {
  color: #fff;
  background-color: #00155a;
}

.navLink_active svg {
  fill: #4195f5;
}

.sideMenu_link{
  background: #00155a;
}

span.nav-item.nav__font.show svg {
  fill: #4195f5;
}

.side_nav_link {
  display: flex;
  align-items: baseline;
  // gap: 0.8rem;
  color: #fff;
  text-decoration: none !important;
  padding: 0.5rem 1rem 0.5rem 1.75rem;
  overflow: hidden;
}
.navLink_name{
  display: block;
}

.sidebar-mini.sidebar-collapse {
  .side_nav_link{
    padding: 0.5rem 1rem;
  }
  .navLink_name{
    display: none;
  }
} 
.main-sidebar:hover {
  .side_nav_link{
    padding: 0.5rem 1rem 0.5rem 1.75rem;
  } 
  .navLink_name{
    display: block;
  }
} 

.side_nav_link:hover {
  color: #fff;
  background-color: #00155a;
}

.navLink_active {
  color: #fff;
  // background-color: #00155a;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0f4c60;
}

a.brand-link {
  color: #fff;
}

.icon svg {
  width: 22px;
  height: 22px;
}

.link {
  display: flex;
  color: #fff;
  gap: 12px;
  padding: 0.8rem 1rem;
}

.link:hover {
  color: #fff;
  background: #00155a;
  text-decoration: none;
}

.active {
  background: #00155a;
  color: #fff;
  padding: 0.8rem 1rem;
}

.menu {
  display: flex;
  color: #ffffff;
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
  align-items: center;
}

.menu_item {
  display: flex;
  gap: 15px;
}

.menu_container .link {
  padding-left: 20px;
}

.navLink_name {
  margin-left: 0.5rem;
  margin-bottom: 0;
  text-transform: capitalize;
}

.nav__font {
  font-size: 18px;

  @media (max-width: $xxl) {
    font-size: rem($font-size-regular);
  }
}

@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar::before {
    margin-left: 0;
    width: 3rem;
  }

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 3rem !important;
  }
}

.brandlogosmall {
  display: none;
}

.sidebar-mini.sidebar-collapse .main-sidebar .brandlogosidebar {
  display: none;
}

.sidebar-mini.sidebar-collapse .main-sidebar .brandlogosmall {
  display: block;
  width: 65%;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover .brandlogosmall {
  display: none;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover .brandlogosidebar {
  display: block;
}
