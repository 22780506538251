@import "../../../../styles/_variables";
@import "../../../../styles/_mixins";
@import "../../../../styles/_helpers";
@import "../../../../styles/_elements";

.license {
  margin-top: rem(15px);

  & &__btn-show-switch {
    min-width: rem(180px);
    padding-left: rem(25px);
    padding-right: rem(15px);

    .btn__icon {
      margin-left: rem(10px);
    }
  }

  & &__body {
    padding-top: rem(15px);
  }

  & &__items-holder {
    display: flex;
    flex-wrap: wrap;
    gap: rem(15px);
  }

  & &__item {
    flex: 1 0 rem(300px);
  }
}

.history {
  & &__filter {
    padding: rem(10px) 0;
    border-bottom: 1px hsl(0deg, 0%, 90%) solid;
  }

  & &__body {
    padding-top: rem(15px);
  }

  .section {
    padding-left: rem(7px);
    padding-right: rem(7px);
    padding-bottom: rem(9px);

    >.section__title {
      margin-bottom: rem(5px) !important;
      /*need find more properly approach for avoid use !important*/
      border-radius: rem(20px);
      box-shadow: 0 0 rem(5px) #fff;
    }

    &:first-child~.section {
      margin-top: rem(20px);
    }

    &.section_completed {
      $color: green;
      background-color: mix($color, #fff, 10%);
      border-color: $color;

      .section__title {
        color: darken($color, 10%);
      }
    }

    &.section_assigned {
      $color: $color-accent;
      background-color: mix($color, #fff, 10%);
      border-color: $color;

      .section__title {
        color: darken($color, 30%);
      }
    }

    &.section_paid {
      $color: yellow;
      background-color: mix($color, #fff, 10%);
      border-color: darken($color, 10%);

      .section__title {
        color: darken($color, 40%);
      }
    }

    &.section_rejected {
      $color: $color-danger;
      background-color: mix($color, #fff, 10%);
      border-color: darken($color, 5%);

      .section__title {
        color: darken($color, 30%);
      }
    }

    &.section_deliver {
      $color: violet;
      background-color: mix($color, #fff, 10%);
      border-color: darken($color, 5%);

      .section__title {
        color: darken($color, 50%);
      }
    }
  }

  .table {
    font-size: rem($font-size-min);
    border: none;

    td,
    th {
      padding: 0.3em;
      border-color: hsla(0, 0%, 0%, 0.2);
      color: initial;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }

    thead {
      th {
        background-color: hsla(0, 0%, 0%, 0.05);
        border-top: none;

        &.th_date {
          width: rem(40px);
        }
      }
    }

    tbody {
      tr:last-child>td {
        border-bottom: none;
      }

      td {
        vertical-align: top;
      }
    }
  }
}

.font__sm {
  font-size: smaller;
}

.responsive {
  width: 90%;
  max-height: 250px;
  min-height: 300px;
  display: block;
  margin: auto;
  height: auto;
}

.form__msg_warning {
  color: #f61818;
  text-align: center;
  font-weight: 600;
  padding-top: 1rem !important;
}

.modal .modal__body .deposit {

  /*need optimize further!*/
  .form__item_deposit {
    margin-top: rem(25px);
  }

  .form__inline-items_deposit {
    margin-top: 0;
  }
}

.brief.brief_time-schedule {
  .time-range {
    margin-bottom: rem(13px);
    margin-left: rem(13px);
  }

  @media (max-width: $sm-) {
    .brief__item_time-amount {
      display: block;
    }

    .brief__item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .brief__item-cell_title {
        min-width: 0;
      }
    }
  }
}

.status__container {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: auto;
  text-align: center;
  justify-content: flex-end;
  gap: 1%;
  flex-wrap: wrap;
  padding-left: 1%;
}

.mb__btn__badge {
  margin-bottom: 1.25%;
}

.mb__sm {
  margin-bottom: unset;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem !important;
  }
}

.reason__container {
  gap: unset;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  &__title {
    margin-top: -0.9em !important;
    margin-bottom: 0em;
    padding: 0 0rem;
    background: #fff;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 0;
  }

  & &__reason {
    width: 100%;
    margin: -0.7em 0px 0px !important;
    color: red;
    padding-left: 0.3em;
  }
}