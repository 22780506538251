@import "../../../../styles/_variables";
@import "../../../../styles/_mixins";
@import "../../../../styles/_helpers";
@import "../../../../styles/_elements";

.mass-payments {
  $self: &;

  & &__btn-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(15px) 0 rem(30px);
  }

  & &__btn-start {
    min-width: rem(250px);
  }

  & &__btn-start-icon {
    color: $color-text-invert;
    width: rem(18px) !important;
    height: rem(18px) !important;
  }

  & &__progress-holder {
    margin-bottom: rem(30px);
    padding: rem(30px);
    border: 1px hsl(0deg, 0%, 95%) solid;
    border-radius: $border-rad-lg;
  }

  & &__table {
    th {
      &#{$self}__table-th-amount {
        text-align: center;
      }
    }

    td {
      vertical-align: top;

      &#{$self}__table-td-status {
        position: relative;
        width: rem(150px);
      }

      &#{$self}__table-td-amount {
        text-align: center;
        font-weight: 600;
      }

      &:hover #{$self}__cancel-holder {
        opacity: 1;
      }
    }

    &.table_thead-sticky {
      thead {
        top: rem(-20.5px);
      }
    }
  }

  & &__status-holder {
    display: flex;
    align-items: center;
  }

  & &__status-icon-holder {
    width: rem(30px);
    display: flex;
    align-items: center;
  }

  & &__status-icon {
    font-size: 1.2em;

    &_payed {
      color: $color-success;
    }

    &_rejected {
      color: $color-danger;
    }

    &_canceled {
      color: $color-danger;
    }

    &_pending {
      width: rem(18px) !important;
      height: rem(18px) !important;
    }
  }

  & &__status {
    &_pending {
      opacity: 0.5;
    }

    &_canceled {
      color: $color-danger;
    }
  }

  & &__cancel-holder {
    @include fill;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0.7em;
    background-color: hsla(0, 0%, 100%, 0.7);
    opacity: 0;
    transition: opacity 0.5s linear;
  }

  & &__addr-list {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
      padding: 0.7em 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      & ~ li {
        border-top: 1px $color-border-light solid;
      }
    }
  }

  & &__addr-item {
    padding: 0;
    list-style: none;
  }
}
