.estimated__pay__text {
  display: flex;
  font-weight: 600;
  margin: 0.2rem 0.8rem;
  font-size: 0.9rem;
  gap: 0.3rem;
  white-space: nowrap;
}

.deposit__btn {
  white-space: nowrap;
  padding: 0 0.8rem;
}
