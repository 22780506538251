@import "../../styles/_variables";

.screen-users {
  display: flex;
  flex-direction: column;

  & &__form-wrapper {
    width: 100%;
    position: relative;
    z-index: 3;
  }

  & &__container {
    position: relative;
    z-index: 7;
    border: 1px $color-border solid;

    @media (min-width: $xl) {
      &.container_narrow {
        border-top-right-radius: $border-rad-sm;
      }
    }

    @media (min-width: $xxl) {
      &:not(.container_narrow) {
        border-top-right-radius: $border-rad-sm;
        border-top-left-radius: $border-rad-sm;
      }
    }
  }

  & &__form-body {
    max-width: rem(500px);
    margin: 0 auto;
    padding: rem(20px) 0 rem(45px);
  }

  .form__item {
    margin: 0;
    min-height: rem(70px);
  }

  .form-body-invited {
    .form__item {
      margin-bottom: rem(-24px);
    }
  }
}

.user_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 100%;
  min-height: 60vh !important;
}

.table_users {
  .btn {
    white-space: nowrap;
    padding-right: 0.7em;
    padding-left: 0.7em;
  }

  td.table__td-time {
    min-width: rem(225px);
  }
}

.tabs.tabs_users {
  .tabs__item {
    &_log {
      .tabs__icon-group {
        .tabs__icon {
          transform: translateY(-11%);

          &_add {
            transform: translate(40%, 20%);
          }
        }
      }
    }
  }
}

.mainTabWidth {
  max-width: 90% !important;
}

.mainTabBackground {
  background: white;
}
