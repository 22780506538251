.dhx_grid {
  z-index: 1;
}

.textBold {
  font-weight: 500;
}

.inline {
  padding-top: 22px;
}

.shift_num input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.num_input input {
  width: 70px !important;
  height: 2.4rem
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  // margin-right: 10px;
}

@media (max-width: 1440px) {

  .apply_btn {
    width: 80px !important;
  }

  .reset_btn {
    width: 80px !important;
  }

  .switch_on:before,
  .switch_on:after {
    height: 29px !important;
    line-height: 29px !important;
  }
}

.filter_side {
  width: 100%;
  position: relative;
}

.filter_side .filter__container {
  border: 1px #dadada solid;
  border-top: none;
  background: #fff;
}

.filter_side .filter__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.25rem;
  padding: 0.5rem 0;
}

// toggle button
.toggle_container {
  text-align: center;
  margin-left: 20px;
}

.toggle-switch {
  position: relative;
  width: 75px;
  text-align: left;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}

.switch_on {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.switch_on:before,
.switch_on:after {
  float: left;
  width: 50%;
  height: 32px;
  padding: 0;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.switch_on:before {
  content: "ON";
  padding-left: 15px;
  background-color: #060;
  color: #fff;
}

.switch_on:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}

.switch_off {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked+.label .switch_on {
  margin-left: 0;
}

.checkbox:checked+.label .switch_off {
  right: 0px;
}